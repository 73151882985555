
.price_transform {
    display: flex;
    width: fit-content;
    text-align: right;
    position: relative;
    .from_price {
      font-size: 18px;

      line-height: 24px;
      color: var(--ion-color-primary);
      font-family: Tusker, sans-serif;
      padding-right: 5px;
    }
    .full_price{
        flex: 1 1 85%;
        font-size: 20px;

        line-height: 24px;
        color: var(--ion-color-primary);
        font-family: Tusker, sans-serif;

      }
      .rest_price{
        flex: 1 1 25%;
        font-size: 13px;
        line-height: 14px;
        color: var(--ion-color-primary);
        font-family: Tusker, sans-serif;
        position: absolute;
        right: 4px;
      }
      .rest_price_hide{
        flex: 1 1 25%;
        font-size: 13px;
        line-height: 14px;
        color: var(--ion-color-primary);
        font-family: Tusker, sans-serif;
        opacity: 0;
      }
    }
.rec-price {
  .full_price {
    font-size: 26px;
    line-height: 28px;
  }
  .full_price{
    color: white
  }
  .rest_price{
    color: white;
  } 
}